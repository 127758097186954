import dashboard from './dashboard';
import editProfile from './editProfile';
import productUpload from './productUpload';

export default {
    productUpload,
    editProfile,
    dashboard,
    pickupOf: 'Abholung von {{name}}',
    returnOf: 'Rückgabe von {{name}}',
    addToCalendar: 'Zum Kalendar hinzufügen',
    uploadAProduct: 'Produkt Hochladen',
    defaultFeedbackComment: 'Die Vermietung ist perfekt gelaufen, immer wieder gerne! #TeilenIstDasNeueHaben',
    defaultFeedback: [
        'Die Vermietung ist perfekt gelaufen, immer wieder gerne! #TeilenIstDasNeueHaben',
        'Ich bin sehr zufrieden mit der Anmietung dieses Artikels. Der Vermieter war äußerst freundlich und das Produkt war genau wie beschrieben. Die Kommunikation war klar und die Übergabe reibungslos. Kann ich nur weiterempfehlen!',
        'Alles lief perfekt! Der Artikel war in einem hervorragenden Zustand und der Vermieter war sehr hilfsbereit und zuvorkommend. Vielen Dank für den tollen Service!',
        'Hervorragende Erfahrung! Der Vermieter war pünktlich und sehr professionell. Das gemietete Produkt funktionierte einwandfrei und entsprach vollständig der Beschreibung. Gerne wieder!',
        'Sehr angenehme Miete! Der Kontakt mit dem Vermieter war unkompliziert und freundlich. Der Artikel wurde sauber und in einwandfreiem Zustand übergeben. Top!',
        'Ich kann diesen Vermieter definitiv empfehlen. Sehr zuverlässig und entgegenkommend. Der Artikel war wie neu und funktionierte tadellos. Toller Service!',
        'Die Anmietung verlief super einfach und der Vermieter war sehr flexibel bei der Übergabe. Das Produkt war top und hat meine Erwartungen übertroffen. Gerne wieder!',
        'Exzellenter Zustand des Artikels und sehr freundlicher Vermieter. Alles wurde detailliert erklärt und die Kommunikation war spitze. Sehr empfehlenswert!',
        'Wunderbare Erfahrung! Der Artikel war genau wie beschrieben und der Vermieter war sehr geduldig und hilfsbereit. Die Abwicklung war einfach und schnell. Ich bin sehr zufrieden!',
        'Sehr professioneller und angenehmer Kontakt. Der Artikel war in perfektem Zustand und die Übergabe erfolgte pünktlich und unkompliziert. Vielen Dank für den tollen Service!',
        'Ein großartiges Mieteerlebnis! Der Vermieter war super freundlich und der Artikel hat einwandfrei funktioniert. Ich war mit allem rundum zufrieden und werde sicherlich wieder hier mieten.',
        'Total unkompliziert und super nett! Der Artikel war top in Schuss und alles hat ohne Probleme geklappt. Gerne wieder!',
        'Mega zufrieden! Echt cooler Typ, der Vermieter. Alles lief glatt und der Artikel war einwandfrei. Top Erfahrung!',
        'Super easy und entspannt. Die Übergabe war fix und der Artikel hat meine Erwartungen voll erfüllt. Voll gut gelaufen!',
        "Alles chillig! Super Kontakt und der Artikel war genau wie versprochen. Hab's echt genossen und würde defintiv wieder mieten!",
        'Absolut reibungslos! Cooler Kontakt und der Artikel war spitze. Alles super einfach und schnell. Würde ich jedem empfehlen!',
    ],
    feedbackIconFilled: 'Feedback-Symbol - Ausgefüllt',
    feedbackIconEmpty: 'Feedback-Symbol - Leer',
    feedbackStep1: 'Bewerte Deine Miete in 1-5 Boomerangs',
    feedbackStep2: 'Wie fandest du die Kommunikation?',
    feedbackStep3: 'Wie fandest du die Übergabe oder den Versand?',
    feedbackStep4: {
        overall: 'Gesamtergebnis',
        comment: 'Text (optional)',
        typeSomething: 'Schreibe etwas ...',
    },
    rate: 'Bewerten',
    showReceipt: 'Rechnung anzeigen',
    ergoTransactions: 'Bis zu 15.000 € versicherte Transaktionen gemäß fainins AGB.',
    approx: 'ca.',
    time: 'Uhr',
    requestAgain: 'Erneut anfragen',
    popUpDisabled: 'Pop-ups sind deaktiviert!',
    enablePopups:
        'Erlaube Pop-ups in deinen Browser-Einstellungen um mit Paypal zu zahlen. Wenn du Pop-ups nicht erlauben möchtest, zahle mit Debit- oder Kreditkarte oder bezahle in der fainin App.',
    noFavorites: 'Aktuell hast du keine Favoriten.',
    searchNow: 'Jetzt stöbern!',
    noProducts: 'Aktuell hast du keine Produkte inseriert.',
    lendNow: 'Jetzt verleihen!',
    moreFunWithListings: 'fainin macht viel mehr Spaß mit mehr Produkten!',
    rememberFavorites: 'Merk’ Dir Artikel zur Miete vor und buche sie sobald Du genaue Pläne hast.',
    rentOrUpload: 'Miete oder lade einen Artikel hoch um zu starten!',
    noChatsYet: 'Noch keine Chats',
    createReqAndChat: 'Stelle eine Anfrage und schreibe mit Leuten.',
    browse: 'Jetzt stöbern',
    share: 'Teilen',
    shareProduct: 'Schau dir dieses sichere Mietangebot auf fainin an:',
    shareCommunity: 'Schau dir diese Community auf fainin an:',
    sumsubError: 'Es ist etwas schief gegangen. Bitte melde dich bei uns solltest du weiterhin Probleme haben!',
    sumsubDelayError: "Leider dauert dieser Vorgang länger als sonst... versuch's nochmal!",
    sumsubFallback: {
        title: 'Manueller Verifizierungslink generiert',
        info: 'Etwas ist beim Identifikationsprozess schiefgelaufen. Bitte nutze den folgenden Link, um die Identifikation manuell abzuschließen. Sollten weiterhin Probleme auftreten, kontaktiere uns bitte.',
    },
    rentalDuration: 'Mietdauer',
    totalPayment: 'Gesamtbetrag',
    totalPayout: 'Auszahlung',
    toRequest: 'Zur Anfrage',
    toOrder: 'Zur Bestellung',
    logout: 'Ausloggen',
    period: 'Zeitraum',
    uploadNewProduct: 'Neues Produkt hochladen',
    requestList: {
        rentalDetails: 'Rental Details',
        pickup: 'Pickup',
        return: 'Return',
        rentalPrice: 'Rental Price',
        perDay: 'Price per Day',
        perHour: 'Price per Hour',
        daysTotal: 'Total Days',
        hoursTotal: 'Total Hours',
        fees: 'Servicefee',
        sendMsg: 'To Chat',
        decline: 'Decline',
        accept: 'Accept',
        cancel: 'Cancel',
        noRentalRequests: 'You have no rental requests!',
        findProduct: 'Find your favorite product',
        noLendRequests: 'No requests for your products!',
        tryUploading: 'Try uploading a new exciting product.',
        upload: 'Upload',
        requester: 'Anfragensteller',
        product: 'Produkt',
        products: 'Produkte',
        days: 'Tage',
        total: 'Summe',
        start: 'Start',
        end: 'Ende',
        requested: 'Angefragt',
        viewDetails: 'Details anzeigen',
    },
    myProfile: {
        info: 'Info',
        badges: 'Abzeichen',
        reviews: 'Bewertungen',
        onTotal: 'auf ingesamt',
        showMoreReviews: 'Weitere Rezensionen Anzeigen',
        myUploadLocation: 'Meine Upload Location',
    },
    statusType: {
        PENDING: 'Anfrage gesendet',
        MYPENDING: 'Anfrage erhalten',
        CANCELLED: 'Storniert',
        DECLINED: 'Abgelehnt',
        ACCEPTED: 'Akzeptiert',
        EXPIRED: 'Abgelaufen',
    },
    lender: 'Vermieter:in',
    noData: 'Keine Daten',
    createdAt: 'Hergestellt in:',
    chatWith: 'Chat mit',
    your: 'Dein',
    product: 'Produkt',
    rent: 'Mieten',
    lend: 'Vermieten',
    greatNews:
        'Jemand möchte deinen Artikel mieten. Nimm die Anfrage an und besprich alles weitere im Chat, damit alles klappt. Wenn es nicht geht, dann schreib der anderen Person dies bitte und lehne die Anfrage ab.',
    showRequest: 'Anfrage anzeigen',
    downloadNoteOnRequest: 'Um deine Anfragen zu bearbeiten, lade dir bitte unsere App herunter.',
    requestAccepted: 'Anfrage akzeptiert',
    downloadApp: 'App herunterladen',
    requestReceived: 'Mietanfrage',
    selectPreference: 'Wähle deine Präferenz',
    whyFainin: 'Warum verwendest du fainin?',
    requestDeclined: 'Anfrage Abgelehnt',
    requestCanceled: 'Anfrage Storniert',
    canceled: 'Storniert',
    requestSent: 'Anfrage gesendet',
    requestSentMsg: 'Um die Transaktion zu vervollständigen solltest Du Dich über fainins App anmelden & bezahlen.',
    request: {
        rentalDetails: 'Vermietungsdetails',
        pickup: 'Abholung',
        return: 'Rückgabe',
        rentalPrice: 'Mietpreis',
        perDay: 'Preis Pro Tag',
        perHour: 'Preis Pro Stunde',
        daysTotal: 'Tage gesamt',
        hoursTotal: 'Stunden gesamt',
        fees: 'Servicegebühr',
        total: 'Gesamt',
        sendMsg: 'Zum Chat',
        decline: 'Ablehnen',
        accept: 'Annehmen',
        cancel: 'Abbrechen',
        noRentalRequests: 'Du hast noch keine Mietanfragen!',
        findProduct: 'Finde dein Lieblingsprodukt',
        noLendRequests: 'Du hast noch keine Anfragen für deine Produkte!',
        tryUploading: 'Lade ein spannendes Produkt hoch',
        upload: 'Hochladen',
        subTotal: 'Zwischensumme',
    },
    order: {
        rentalEnded: 'Ausleihe beendet',
        pay: 'Bezahle',
        waitPayment: 'Warte auf Zahlung',
        status: 'Status',
        filterOptions: {
            requested: 'Angefragt',
            active: 'To Do',
            pending: 'Laufend',
            processed: 'Wartet',
            completed: 'Beendet',
            rented: 'Ausgeliehen',
            paymentrequired: 'Zu zahlen',
            ended: 'Beendet',
            cancelled: 'Storniert',
            paid: 'Bezahlt',
        },
        rentalOngoing: 'Vermietung läuft',
        endRental: 'Vermietung beenden',
        noRentedOrder: 'Verdiene Geld beim Verleihen',
        uploadNow: 'Jetzt inserieren!',
        giveFeedback: 'Bewertung abgeben',
        waitFeedback: 'Bewertung ausstehend',
        confirmDelivery: 'Bestätigen',
        confirmReturn: 'Rückgabe bestätigen',
        awaitRefund: 'Warte auf Rückerstattung',
        verificationPending: 'Verifizierung ausstehend',
        discount: 'Rabatt nach Preisstaffelung',
    },
    orderTimeline: {
        completed: 'Abgeschlossen',
        feedback: 'Feedback',
        itemReturned: 'Artikel zurückgegeben',
        uploadPhotosReturn:
            'Dokumentiere den Zustand des Artikels nach der Rückgabe. Das Hochladen der Fotos schließt die Vermietung ab.',
        return: 'Rückgabe',
        addedComment: 'Kommentar hinzugefügt! Bestätige die Übergabe, um zu speichern.',
        productWithRenter: 'Der Artikel befindet sich beim Mieter.',
        deliveryDone: 'Abholung abgeschlossen',
        rentalAwaits: 'Verleih wartet',
        rentalAwaitsRenter:
            'Die leihende Person startet die Miete, indem sie mindestens ein Foto des Gegenstands hochlädt, um den Ist-Zustand zu dokumentieren.',
        validatingCustomer: 'Verifizierung des Mieters',
        validatingCustomerHint:
            'Nach dem Abschluss der Verifizierung kann bezahlt werden. Bei Fragen zu diesem Vorgang melde dich gerne bei unserem Support.',
        arrangingPayment: 'Zahlung ausstehend',
        arrangingPaymentHint:
            'Bitte bedenke, dass nur Transaktionen über unserer Platform abgesichert sind. Bei Fragen wende dich gerne an unseren Support.',
        paymentSuccess: 'Zahlung eingegeangen',
        cancelled: 'Cancelled',
        requestAccepted: 'Anfrage angenommen',
        verify: 'Verifizieren',
        verifyNow: 'Jetzt verifizieren',
        verificationStarted: 'Verifizierung durchgeführt',
        verificationInProcess: 'Verifizierung in Bearbeitung',
        verificationDone: 'Verifizierung abgeschlossen',
        rentalStarted: 'Die Miete starten',
        productWithYou: 'Der Artikel befindet sich bei Dir.',
        returnAwaitsOwner: 'Der Vermieter endet den Verleih mit Fotos zur Dokumentation des Artikelzustands.',
        rentalOngoing: 'Verleih läuft',
        refund: 'Erstattung',
    },
    orderRequestStateText: {
        requestReceivedEditNow: 'Anfrage erhalten: Jetzt bearbeiten!',
        requestReceivedProfile: 'Anfrage erhalten: Profil vervollständigen',
        requestAcceptedWait: 'Anfrage akzeptiert: Warte auf Zahlung',
        rentalAwaits: 'Verleih steht an: Übergabe absprechen?',
        ongoingRental: 'Verleih läuft: Rückgabe besprechen?',
        returnStarted: 'Rückgabe startet:',
        uploadPhotosReturn: 'Fotos hochladen & Rückgabe abschließen',
        review: 'Feedback geben',
        awaitReview: 'Warte auf Feedback',
        requestCancelledRenter: 'Anfrage storniert: Mieter:in kontaktieren',
        requestDeclinedRenter: 'Anfrage abgelehnt: Mieter:in kontaktieren',
        requestSentWait: 'Artikel angefragt: Warte auf Antwort',
        requestAcceptedVerify: 'Anfrage akzeptiert. Jetzt verifizieren!',
        requestAcceptedVerifyPending: 'Anfrage akzeptiert. Verifizierung in Bearbeitung',
        requestAcceptedPay: 'Anfrage akzeptiert. Jetzt zahlen!',
        rentalStarted: 'Verleih startet:',
        uploadPhotosRent: 'Fotos hochladen & Verleih beginnen',
        requestCancelledLender: 'Anfrage storniert: Vermieter:in kontaktieren',
        requestDeclinedLender: 'Anfrage abgelehnt: Vermieter:in kontaktieren',
        awaitRefundOwner: 'Renter requested refund! Mieter:in kontaktieren',
        awaitRefundRenter: 'Renter requested refund! Vermieter:in kontaktieren',
    },
    verifyAccount: 'Verifizieren',
    return: 'Zurück geben',
    uploadPhotos: 'Fotos hochladen',
    getRefund: 'Erstattung beantragen',
    chat: 'Chat',
    renter: 'Mieter:in',
    requestedFrom: 'Anfragende Person',
    payNow: 'Jetzt bezahlen',
    congratsNiceChoice: 'Herzlichen Glückwunsch, Du hast eine tolle Wahl getroffen. Jetzt nur noch bezahlen',
    fullDownloadApp: 'Für eine vollständige Nutzererfahrung benötigst Du noch fainins App.',
    back: 'Zurück',
    payment: {
        paymentSucceeded: 'Zahlung erfolgreich!',
        paymentProcessing: 'Deine Zahlung wird bearbeitet.',
        paymentFailed: 'Deine Zahlung war nicht erfolgreich, bitte versuche es erneut.',
        somethingWrong: 'Schief gelaufen.',
        unableAuthenticate: 'Wir konnten Dein Zahlungsmethode nicht authentifizieren. Bitte versuche es erneut.',
        cardError: 'Deine Karte ist nicht ausreichend gedeckt.',
        validationError: 'Validierungsfehler',
    },
    comment: 'Anmerkung',
    cancel: 'Abbrechen',
    refundReason: 'Grund der Rückerstattung*',
    completeProfile: 'Profil',
    checkIban: 'Deine IBAN, Postleitzahl und Ort müssen korrekt sein',
    edit: 'Profil bearbeiten',
    editOnly: 'Bearbeiten',
    rented: 'Ausgeliehen',
    lent: 'Verliehen',
    welcome: 'Willkommen',
    rentals: 'Ausleihen',
    badges: 'Abzeichen',
    messages: 'Nachrichten',
    menu: {
        discover: 'Stöbern',
        dashboard: 'Dashboard',
        products: 'Meine Produkte',
        orders: 'Ausleihen',
        chats: 'Chats',
        profile: 'Profil',
        uploadProduct: 'Produkt Hochladen',
        addressBook: 'Addressen',
        help: 'Hilfe',
        favorites: 'Favoriten',
        yourOffers: 'Deine Angebote',
        community: 'Community',
    },
    blockUser: '{{userName}} blockieren',
    noMsgs: 'Es gibt noch keine Nachrichten',
    sendAMsg: 'Sende eine Nachricht an {{userName}}',
    messageplaceholder: 'Nachricht',
    onboarding: {
        step1: {
            title: 'Artikel hochladen',
            info: 'Nutze fainin innerhalb von zwei Minuten, indem Du Deinen ersten Artikel hochlädst.',
            buttonName: 'Weiter',
        },
        step2: {
            title: 'Chatte & sende Anfragen',
            info: 'Frag einen Artikel an, um ihn von Deinen Nachbarn zu mieten. Für die Details, chatte einfach mit dem Vermieter.',
            buttonName: 'Weiter',
        },
        step3: {
            title: 'Vervollständige Dein Profil',
            info: 'Ergänze Dein Profil um fainin vollständig zu nutzen.',
            buttonName: 'Start',
        },
        skip: {
            title: 'Intro überspringen?',
            info: 'Möchtest Du das Intro überspringen und jetzt anfangen zu teilen?',

            buttonName: 'Überspringen',
        },
    },
    productTable: {
        name: 'Produkt Titel',
        actions: 'Aktionen',
        description: 'Beschreibung',
        purchasePrice: 'Preis',
        createdAt: 'Datum',
    },
};
