import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import {
    ActiveCustomer,
    ActiveProfile,
    GetActiveCommunities,
    GetActiveCustomer,
    GetActiveProfileQuery,
    GetActiveUser,
    GetFavoritesIdsList,
    GetOrderWithFeedbackRemaining,
    GetOwnerStats,
    GetUnreadMarkersQuery,
    GetUnreadMarkersQueryVariables,
    Order,
    RegisterDevice,
    Request,
    UnreadMarker,
    UpdateProfileInput,
    UpdateProfileMutation,
    UpdateProfileMutationVariables,
    UserEntityRelation,
} from '@api/generated-types';
import {
    GET_ACTIVE_CUSTOMER,
    GET_ACTIVE_USER,
    GET_FAVORITES_IDS_LIST,
    GET_UNREAD_MARKERS,
    REGISTER_DEVICE_TOKEN,
} from '@api/vendure/documents.graphql';
import { RequestReceivedDialogComponent } from '@core/components/request-received-dialog/request-received-dialog.component';
import { DataService } from '@core/providers/data/data.service';
import { OrderStateService } from '@core/providers/state/order-state.service';
import { StateService } from '@core/providers/state/state.service';
import { WebViewService } from '@core/providers/webview/web-view.service';
import { Logger } from '@core/utils/logger';
import Clarity from '@microsoft/clarity';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
    GET_ACTIVE_PROFILE,
    GET_OWNER_STATISTICS,
    UPDATE_PROFILE,
} from 'src/app/account/components/account-edit-profile/account-edit-profile.graphql';
import { EOrderState } from 'src/app/account/components/account-order-detail/order-state.enum';
import { EFilterOption } from 'src/app/account/components/account-order-list/e-filter-option';
import { GET_ACTIVE_COMMUNITIES } from '../../../account/components/account-community-list/account-community-list.graphql';
import { GET_ORDER_WITH_FEEDBACK_REMAINING } from '../../../account/components/account-order-list/account-order-list.graphql';
import { ModalService } from '../modal/modal.service';
import { SocketioClientService } from '../socketio-client.service';
import { SelectPreferenceDialogComponent } from '@core/components/select-preference-dialog/select-preference-dialog.component';
const logger = new Logger('UserService');

@Injectable({
    providedIn: 'root',
})
export class UserService {
    checkOrdersWithAwaitingFeedbacks = new BehaviorSubject<boolean>(false);
    unreadMarkers = new BehaviorSubject<UnreadMarker[]>([]);

    constructor(
        private dataService: DataService,
        private state: StateService,
        private modalService: ModalService,
        private socketioClientService: SocketioClientService,
        @Inject(PLATFORM_ID) private platformId: any,
        private orderService: OrderStateService,
        private webViewService: WebViewService,
    ) {
        if (isPlatformServer(this.platformId)) {
            return;
        } // Do not load any user data on server (Apollo client doesn't work anyway)
        this.init();
        this.state
            .select(state => state.signedIn)
            .subscribe(signedIn => {
                logger.debug('signedIn:', signedIn);
                if (signedIn) {
                    this.loadUserData().then(async () => {
                        await this.initWebTutorial();
                    });
                } else {
                    this.dataService.clearCache().then();
                    this.state.resetState();
                }
            });
    }

    public async refreshOrderRequestNotifications() {
        await this.refreshRequests();
        await this.refreshOrders();
        await this.refreshNotifications();
    }

    public async refreshRequests() {
        await this._loadRequests();
    }

    public async refreshOrders() {
        // await this._loadOrders();
    }

    public async refreshNotifications() {
        await this._loadUnreadMarkers();
    }

    /**
     * Gets the current active customer
     * or fetches it in case the signedIn state is true, but the value is undefined.
     * @param fetch
     */
    public async getActiveCustomer(fetch: boolean = false) {
        if (fetch) return this.fetchActiveCustomer();
        const activeCustomer = this.state.currentState('activeCustomer');
        if (activeCustomer) {
            return Promise.resolve(activeCustomer);
        }
        const signedIn = this.state.currentState('signedIn');
        if (signedIn) return this.fetchActiveCustomer();
        return Promise.resolve(undefined);
    }

    /**
     * Gets the current active profile
     * or fetches it in case the signedIn state is true, but the value is undefined.
     * @param fetch
     */
    public async getActiveProfile(fetch: boolean = false) {
        if (fetch) return this.fetchActiveProfile();
        const activeProfile = this.state.currentState('activeProfile');
        if (activeProfile) {
            return Promise.resolve(activeProfile);
        }
        const signedIn = this.state.currentState('signedIn');
        if (signedIn) return this.fetchActiveProfile();
        return Promise.resolve(undefined);
    }

    /**
     * Resets active user data and all order states
     */
    public resetUserStates() {
        this.orderService.resetState();
        this.state.updateState({
            activeProfile: undefined,
            activeCustomer: undefined,
            activeUser: undefined,
        });
    }

    private async fetchActiveCustomer(): Promise<ActiveCustomer.Fragment | undefined> {
        return lastValueFrom(
            this.dataService.query<GetActiveCustomer.Query>(GET_ACTIVE_CUSTOMER, {}, 'network-only').pipe(
                map(value => value.activeCustomer),
                take(1),
            ),
        ).then(activeCustomer => {
            this.state.setState('activeCustomer', activeCustomer);
            // Clarity doesn't store custom identifier as plain text. Instead,
            // the custom ID is hashed on the client before being sent to the servers.
            if (activeCustomer) Clarity.identify(activeCustomer.id);
            return activeCustomer;
        });
    }

    private async fetchActiveProfile(): Promise<ActiveProfile.Fragment | undefined> {
        return lastValueFrom(
            this.dataService.query<GetActiveProfileQuery>(GET_ACTIVE_PROFILE, {}, 'network-only').pipe(
                map(value => value.activeProfile),
                take(1),
            ),
        ).then(activeProfile => {
            this.state.setState('activeProfile', activeProfile);
            if (activeProfile) Clarity.setTag('profileType', activeProfile.type);
            return activeProfile;
        });
    }

    public async fetchOwnerStatistics(): Promise<GetOwnerStats.OwnerStats | undefined> {
        logger.debug('fetchOwnerStatistics');
        return lastValueFrom(
            this.dataService.query<GetOwnerStats.Query>(GET_OWNER_STATISTICS, {}, 'network-only').pipe(
                map(value => value.ownerStats),
                take(1),
            ),
        ).then(ownerStats => {
            this.state.setState('ownerStats', ownerStats);
            return ownerStats;
        });
    }

    public async fetchActiveUser() {
        this.dataService
            .query<GetActiveUser.Query>(GET_ACTIVE_USER, undefined, undefined, 'ignore')
            .pipe(take(1))
            .subscribe({
                next: data => {
                    if (data.me) {
                        this.state.updateState({
                            activeUser: data.me,
                            channels: [...data.me.channels],
                            signedIn: true,
                            activePreferences: data.activeUserPreferences || [],
                        });
                    } else {
                        this.state.updateState({
                            activeUser: undefined,
                            channels: [],
                            signedIn: false,
                        });
                    }
                },
                error: error => {
                    if (error.graphQLErrors[0]?.extensions.code === 'FORBIDDEN') {
                        this.state.setState('activeUser', undefined);
                        this.state.setState('signedIn', false);
                    }
                    logger.error('init error', error);
                },
            });
    }

    /**
     * Updates the current active profile entity and the activeProfile app state as well
     * @param input UpdateProfileInput
     */
    public async updateActiveProfile(input: UpdateProfileInput) {
        try {
            await lastValueFrom(
                this.dataService
                    .mutate<UpdateProfileMutation, UpdateProfileMutationVariables>(UPDATE_PROFILE, {
                        input,
                    })
                    .pipe(take(1)),
            );
            await this.fetchActiveProfile();
        } catch (error) {
            throw error;
        }
    }

    private async initWebTutorial() {
        const activeCustomer = await this.getActiveCustomer();
        const webTutorialSeen: boolean | undefined = activeCustomer?.user?.customFields?.webTutorialSeen;
        logger.debug('UserService Web Tutorial Seen: ', webTutorialSeen);
        this.state.setState('webTutorialSeen', webTutorialSeen ? webTutorialSeen : false);
    }

    private init() {
        logger.debug('init');
        this.fetchActiveUser().then(() => logger.debug('init done'));
    }

    private async loadUserData() {
        try {
            // in case user wasn't signed in before
            if (this.state.currentState('activeUser')) {
            }
            logger.debug('loadActiveProfile');
            await this.fetchActiveProfile();
            logger.debug('loadActiveCustomer');
            await this.fetchActiveCustomer();
            this.fetchOwnerStatistics();
            logger.debug('loadRequestsAndDialog');
            await this._loadRequestsAndShowDialog();
            logger.debug('initWebSocket');
            await this.initSocketConnections();
            logger.debug('loading unread entities');
            await this._loadUnreadMarkers();
            logger.debug('loading FavoriteIds');
            await this._loadFavoriteIds();
            logger.debug('loading communities');
            this._loadCommunities();
            logger.debug('loadUserData End');
        } catch (e: any) {
            logger.error('Something went wrong:' + e.message);
        }
    }

    private async initSocketConnections() {
        // Refresh list on requestStatusChange and orderStateChange
        if (!this.socketioClientService.socket) {
            this.socketioClientService.connect();
        }
        this.socketioClientService.onRequestStatusChange().subscribe(data => {
            this.processRequestChange(data);
        });
        this.socketioClientService.onOrderStateChange().subscribe(data => {
            this.processOrderChange(data);
        });
    }

    private async _loadRequestsAndShowDialog(): Promise<void> {
        let requests = await this._loadRequests();

        if (!requests || !requests.length) {
            this.checkOrdersWithAwaitingFeedbacks.next(true);
            return; // Don't need to show dialog if there is/are no request(s)
        }

        // Filter for requests that I received
        requests = requests.filter(r => r.ownerProfile.id === this.state.currentState('activeProfile')?.id);

        if (!requests || !requests.length) {
            this.checkOrdersWithAwaitingFeedbacks.next(true);
            return; // Don't need to show dialog if there is/are no filtered request(s)
        }

        // Show dialog
        this.modalService
            .fromComponent(RequestReceivedDialogComponent, { locals: { requests }, closable: true })
            .subscribe(confirmation => {
                /**
                 * If the dialog is closed instead of clicking dialog button
                 */
                if (!confirmation) {
                    this.checkOrdersWithAwaitingFeedbacks.next(true);
                }
            }); // Subscribe to activate X(close) button
    }

    fetchOrderWithAwaitingFeedback(): Promise<Order | undefined> {
        return lastValueFrom(
            this.dataService
                .query<GetOrderWithFeedbackRemaining.Query, GetOrderWithFeedbackRemaining.Variables>(
                    GET_ORDER_WITH_FEEDBACK_REMAINING,
                    {},
                    'no-cache',
                )
                .pipe(
                    take(1),
                    map(data => data.orderWithFeedbackRemaining as Order),
                ),
        );
    }

    private async _loadRequests(): Promise<Request[]> {
        return await this.orderService.fetchList(EFilterOption.REQUESTED).then(value => value.items as Request[]);
    }

    private _loadFavoriteIds() {
        logger.debug('_loadFavoriteIds');
        this.dataService
            .query<GetFavoritesIdsList.Query>(GET_FAVORITES_IDS_LIST)
            .pipe(take(1))
            .subscribe(data => {
                logger.debug('_loadFavoriteIds', 'success');
                this.state.setState(
                    'favoriteList',
                    (data.favorites.items || []).map(value => value.id),
                );
            });
    }

    private _loadCommunities() {
        this.dataService
            .query<GetActiveCommunities.Query, GetActiveCommunities.Variables>(
                GET_ACTIVE_COMMUNITIES,
                {},
                'cache-and-network',
            )
            .pipe(take(1))
            .subscribe(data => {
                this.state.setState('communities', [...data.activeCommunities] ?? this.emptyPaginatedList());
            });
    }

    private async _loadUnreadMarkers() {
        const { unreadMarkers } = await lastValueFrom(
            this.dataService
                .query<GetUnreadMarkersQuery, GetUnreadMarkersQueryVariables>(GET_UNREAD_MARKERS, {}, 'no-cache')
                .pipe(take(1)),
        );
        this.unreadMarkers.next([...unreadMarkers]); // take copy so it can be changed later
    }

    private async processRequestChange(data: { request: Request }) {
        if (!data?.request) {
            logger.error('requestStatusChange -> request empty');
            return;
        }
        logger.debug('Refreshing list. Request change: ' + JSON.stringify(data));
        await Promise.all([this.refreshRequests(), this.refreshNotifications()]);
    }

    private async processOrderChange(data: { order: any }) {
        if (!data?.order) {
            logger.error('orderStateChange -> order empty');
            return;
        }
        logger.debug('Refreshing list. Order change: ' + JSON.stringify(data));
        if (data.order.state === EOrderState.ArrangingPayment) {
            // means that the order was just created
            await this.refreshRequests();
        }
        await Promise.all([this.refreshNotifications()]);
        await this.orderService.refreshListForOrder(data.order);
    }

    /**
     * Inside a webview this function attempts to fetch the device token
     * for push notifications
     */
    public registerDeviceToken() {
        if (this.webViewService.isWebView) {
            logger.debug('registerDeviceToken');
            this.webViewService
                .requestDeviceToken()
                .then(payload => {
                    if (payload && payload.token) {
                        logger.debug('registerDeviceToken', payload.token);

                        this.dataService
                            .mutate<RegisterDevice.Mutation, RegisterDevice.Variables>(REGISTER_DEVICE_TOKEN, {
                                input: {
                                    deviceToken: payload.token,
                                    platform: payload.platform,
                                    modelName: payload.modelName ?? undefined,
                                    deviceName: payload.deviceName ?? undefined,
                                },
                            })
                            .pipe(take(1))
                            .subscribe(() => {});
                    }
                })
                .catch(reason => logger.error('registerDeviceToken', reason));
        }
    }
    private emptyPaginatedList() {
        return { totalItems: 0, items: [] };
    }
}
